.section {
    height: 60vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
        font-size: 2.5rem;
        margin: 0;
    }

    .content {
        display: flex;
        flex-direction: column;
    }
}