.btn {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &--sm {
        padding: 5px 10px;
        font-size: 12px;
    }

    &--md {
        padding: 10px 20px;
        font-size: 14px;
    }

    &--lg {
        padding: 15px 30px;
        font-size: 16px;
    }

    &--xl {
        padding: 20px 40px;
        font-size: 20px;
    }

    &--primary {
        background-color: var(--dark);
        color: var(--white);
    }

    &--secondary {
        background-color: var(--blue);
        color: var(--white);
    }

    &--danger {
        background-color: var(--orange);
        color: var(--dark);
    }

    &--link {
        padding: 0;
        border-radius: 0;
        color: var(--dark);
    }

    &--none {
        padding: 0;
        border-radius: 0;
        background-color: inherit;
        color: inherit;
    }

    &:hover {
        opacity: 0.9;
    }
}

.icon {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
}