.hero {
    background-color: var(--light);
    color: var(--dark);
    align-items: center;

    .title {
        font-size: 2.5rem;
        margin: 0;
    }

    .cta {
        align-self: flex-end;
    }
}