:root {
  --light: #dbe4ee;
  --blue: #4361ee;
  --dark: #000b3d;
  --orange: #f17300;
  --indigo: #54008c;
  --white: white;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}